<template>
  <div class="memory-card" @click="flipCard" :class="{ 'flipped': isFlipped || card.matched }">
    <div class="card-inner">
      <div class="card-front">
        {{ card.value }}
      </div>
      <div class="card-back">
        ?
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MemoryCard',
    props: {
      card: Object,
      isFlipped: Boolean,
    },
    methods: {
      flipCard() {
        if (!this.card.matched && !this.isFlipped) {
          this.$emit('flip-card', this.card);
        }
      },
    },
  }
</script>
  
<style scoped>
.memory-card {
  width: 65px;
  height: 65px;
  perspective: 1000px;
  margin: 4px;
  cursor: pointer;
}
.memory-card .card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.memory-card.flipped .card-inner {
  transform: rotateY(180deg);
}
.memory-card .card-front,
.memory-card .card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
  border-radius: 10px;
}
.memory-card .card-front {
  background-color: #fff;
  transform: rotateY(180deg);
  border: 2px solid #007BFF;
  color: #007BFF;
}
.memory-card .card-back {
  background-color: #007BFF;
  color: white;
}
.memory-card.matched {
  visibility: hidden;
}
</style>
  
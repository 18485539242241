// src/data/cards.js
export const cardsData = {
    programming: [
      { level: 1, cost: 10, description: "Fundamentals of the chosen programming language." },
      { level: 2, cost: 30, description: "Advanced knowledge and application of the chosen language." },
      { level: 3, cost: 50, description: "Complex algorithms and programming techniques." },
      { level: 4, cost: 80, description: "Deep understanding of the language and ability to create complex projects." },
      { level: 5, cost: 120, description: "High level of mastery, ability for innovation and complex architectural solutions." }
    ],
    design: [
      { level: 1, cost: 15, description: "Fundamental principles of design and composition." },
      { level: 2, cost: 35, description: "Working with graphic editors and creating visual content." },
      { level: 3, cost: 55, description: "Improving user interface and user experience." },
      { level: 4, cost: 90, description: "Creating designs that adapt to different devices and screens." },
      { level: 5, cost: 130, description: "Developing unique and innovative design solutions." }
    ],
    dataAnalysis: [
      { level: 1, cost: 20, description: "Basic methods of data analysis and statistics." },
      { level: 2, cost: 40, description: "Training machine learning models and their application." },
      { level: 3, cost: 60, description: "Deep data analysis using specialized tools." },
      { level: 4, cost: 100, description: "Processing and analyzing large volumes of data." },
      { level: 5, cost: 150, description: "Data forecasting and modeling for strategic decision-making." }
    ]
};

<template>
  <div class="container">
    <h1>Memory Game</h1>
    <h2 class="username">username</h2>
    <h3 class="score">{{ totalScore }} 🧠</h3>
    <div class="memory-board">
      <MemoryCard
        v-for="card in cards"
        :key="card.id"
        :card="card"
        :isFlipped="flippedCards.includes(card) || card.matched"
        @flip-card="handleFlipCard"
      />
    </div>
    <nav class="nav">
      <router-link to="/shop">🚀Cards</router-link>
    </nav>
  </div>
</template>

<script>
import MemoryCard from './MemoryCard.vue';

export default {
  name: 'MainApp',
  components: {
    MemoryCard,
  },
  data() {
    return {
      cards: this.generateCards(),
      flippedCards: [],
      totalScore: 0, // Общее количество очков
      userId: null, // ID пользователя
      gameCompleted: false, // Флаг, показывающий завершение игры
    };
  },
  methods: {
    generateCards() {
      let values = Array.from({ length: 16 }, (_, i) => Math.floor(i / 2) + 1);
      values.sort(() => Math.random() - 0.5);
      return values.map((value, index) => ({
        id: index,
        value: value,
        matched: false,
      }));
    },
    handleFlipCard(card) {
      if (this.flippedCards.length < 2 && !this.flippedCards.includes(card)) {
        this.flippedCards.push(card);
        if (this.flippedCards.length === 2) {
          this.checkMatch();
        }
      }
    },
    checkMatch() {
      setTimeout(() => {
        if (this.flippedCards[0].value === this.flippedCards[1].value) {
          this.flippedCards[0].matched = true;
          this.flippedCards[1].matched = true;
        }
        this.flippedCards = [];
        this.checkGameComplete();
      }, 1000);
    },
    checkGameComplete() {
      if (this.cards.every(card => card.matched)) {
        setTimeout(() => {
          this.gameCompleted = true;
          this.resetGame();
        }, 1000);
      }
    },
    resetGame() {
      if (this.gameCompleted) {
        this.totalScore += 8; // Начисляем 8 очков при завершении игры
        this.saveScore(); // Сохраняем обновленные очки
        this.gameCompleted = false;
      }
      this.cards = this.generateCards();
      this.flippedCards = [];
    },
    saveScore() {
      if (this.userId) {
        const tg = window.Telegram.WebApp;
        tg.CloudStorage.setItem(`score_${this.userId}`, this.totalScore.toString(), (error, success) => {
          if (error) {
            console.error('Ошибка при сохранении очков:', error);
          } else {
            console.log('Очки успешно сохранены:', success);
          }
        });
      }
    },
    loadScore() {
      if (this.userId) {
        const tg = window.Telegram.WebApp;
        tg.CloudStorage.getItem(`score_${this.userId}`, (error, value) => {
          if (error) {
            console.error('Ошибка при загрузке очков:', error);
          } else {
            this.totalScore = parseInt(value, 10) || 0;
          }
        });
      }
    },
  },
  mounted() {
    const tg = window.Telegram.WebApp;
    tg.expand();
    tg.setHeaderColor("#007bff");

    if (tg.initDataUnsafe && tg.initDataUnsafe.user) {
      const usercard = document.querySelector('.username');
      usercard.innerText = `${tg.initDataUnsafe.user.first_name}`;
      this.userId = tg.initDataUnsafe.user.id;
      this.loadScore(); // Загрузка очков из CloudStorage при старте игры
    }
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  margin: 0 auto;
}
h1, h2, h3 {
  color: #007BFF;
  margin: 0 0 15px 0;
  font-weight: normal;
}
h1 {
  margin-top: 20px;
}
.score {
  font-weight: bold;
  font-size: 24px;
}
.memory-board {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
}
.nav {
  margin-top: 40px;
  text-align: center;
}
.nav a {
  background-color: #007BFF;
  color: #ffffff;
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  display: block;
}
</style>

<template>
  <router-view></router-view>
</template>

<script>
// Импортируем только необходимые зависимости
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  mounted() {
    // this.clearCloudStorage();
  },
  methods: {
    // clearCloudStorage() {
    //   const tg = window.Telegram.WebApp;

    //   // Получаем список всех ключей в CloudStorage
    //   tg.CloudStorage.getKeys((error, keys) => {
    //     if (error) {
    //       console.error('Ошибка при получении ключей:', error);
    //     } else {
    //       // Удаляем все ключи и их соответствующие значения
    //       tg.CloudStorage.removeItems(keys, (error, success) => {
    //         if (error) {
    //           console.error('Ошибка при удалении данных:', error);
    //         } else if (success) {
    //           console.log('Все данные успешно удалены');
    //           // Дополнительные действия после успешного удаления данных, если нужно
    //         } else {
    //           console.log('Не удалось удалить данные');
    //         }
    //       });
    //     }
    //   });
    // },
  },
});
</script>

<style>
/* Ваши стили для App.vue */
body {
  background-color: #181818;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 10px;
}
</style>

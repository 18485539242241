<template>
  <div class="container">
    <h1>🚀Cards</h1>
    <p class="page-desc">The accumulated brains can be spent studying new technologies</p>
    <h3 class="score">{{ totalScore }} 🧠</h3>
    <div class="cards">
      <div class="card" v-for="(card, cardName) in cards" :key="cardName">
        <h2>{{ getCardName(cardName) }}</h2>
        <img :src="getImageSrc(cardName)" :alt="getCardName(cardName)">
        <div v-if="cardLevels[cardName]">
          <caption>{{ getCurrentLevelDescription(cardName) }}</caption>
          <span>Level: {{ cardLevels[cardName].level }}</span>
          <template v-if="getNextLevelCost(cardName) === 0">
            <span>Max Level Reached</span>
          </template>
          <button v-else @click="upgradeCard(cardName)">
            Upgrade for {{ getNextLevelCost(cardName) }} 🧠
          </button>
        </div>
        <div v-else>
          <span>Level: 1</span>
          <caption>{{ card[0].description }}</caption>
          <button @click="buyCard(cardName)">
            Buy for {{ card[0].cost }} 🧠
          </button>
        </div>
      </div>
    </div>
    <nav class="nav">
      <router-link to="/">🎮Play</router-link>
    </nav>
  </div>
</template>

<script>
import { cardsData } from '@/data/cards.js';
import programmingIcon from '@/assets/icons/1.png';
import designIcon from '@/assets/icons/2.png';
import dataAnalysisIcon from '@/assets/icons/3.png';

export default {
  name: 'ShopCard',
  data() {
    return {
      cards: cardsData,
      cardLevels: {}, // Current levels of each card
      totalScore: 0,
      userId: null,
    };
  },
  methods: {
    loadScoreAndLevels() {
      if (this.userId) {
        const tg = window.Telegram.WebApp;
        tg.CloudStorage.getItem(`score_${this.userId}`, (error, value) => {
          if (error) {
            console.error('Ошибка при загрузке очков:', error);
          } else {
            this.totalScore = parseInt(value, 10) || 0;
            console.log(`Загружено очков: ${this.totalScore}`);
          }
        });

        tg.CloudStorage.getItem(`cardLevels_${this.userId}`, (error, value) => {
          if (error) {
            console.error('Ошибка при загрузке уровней карточек:', error);
          } else {
            this.cardLevels = JSON.parse(value) || {};
            console.log(`Загруженные уровни карточек: ${JSON.stringify(this.cardLevels)}`);
          }
        });
      }
    },
    saveScoreAndLevels() {
      if (this.userId) {
        const tg = window.Telegram.WebApp;
        tg.CloudStorage.setItem(`score_${this.userId}`, this.totalScore.toString(), (error) => {
          if (error) {
            console.error('Ошибка при сохранении очков:', error);
          } else {
            console.log(`Сохранено очков: ${this.totalScore}`);
          }
        });

        tg.CloudStorage.setItem(`cardLevels_${this.userId}`, JSON.stringify(this.cardLevels), (error) => {
          if (error) {
            console.error('Ошибка при сохранении уровней карточек:', error);
          } else {
            console.log(`Сохраненные уровни карточек: ${JSON.stringify(this.cardLevels)}`);
          }
        });
      }
    },
    buyCard(cardName) {
      const card = this.cards[cardName][0];
      if (this.totalScore >= card.cost) {
        this.totalScore -= card.cost;
        this.cardLevels[cardName] = { level: 1, cost: card.cost };
        this.saveScoreAndLevels();
      }
    },
    upgradeCard(cardName) {
      const currentLevel = this.cardLevels[cardName].level;
      const nextLevel = currentLevel + 1;
      if (nextLevel <= this.cards[cardName].length) {
        const nextLevelCost = this.cards[cardName][currentLevel].cost;
        if (this.totalScore >= nextLevelCost) {
          this.totalScore -= nextLevelCost;
          this.cardLevels[cardName].level = nextLevel;
          this.cardLevels[cardName].cost = nextLevelCost;
          this.saveScoreAndLevels();
        }
      }
    },
    canUpgradeCard(cardName) {
      const currentLevel = this.cardLevels[cardName]?.level || 0;
      const nextLevelCost = currentLevel < this.cards[cardName].length ? this.cards[cardName][currentLevel]?.cost : Infinity;
      return currentLevel < this.cards[cardName].length && this.totalScore >= nextLevelCost && nextLevelCost > 0;
    },
    getCurrentLevelDescription(cardName) {
      const currentLevel = this.cardLevels[cardName]?.level || 1;
      return this.cards[cardName][currentLevel - 1].description;
    },
    getNextLevelCost(cardName) {
      const currentLevel = this.cardLevels[cardName]?.level || 1;
      return currentLevel < this.cards[cardName].length ? this.cards[cardName][currentLevel]?.cost : 0;
    },
    getCardName(cardName) {
      const cardNames = {
        programming: 'Programming',
        design: 'Design',
        dataAnalysis: 'Data analysis',
      };
      return cardNames[cardName];
    },
    getImageSrc(cardName) {
      const images = {
        programming: programmingIcon,
        design: designIcon,
        dataAnalysis: dataAnalysisIcon,
      };
      return images[cardName];
    },
  },
  mounted() {
    const tg = window.Telegram.WebApp;

    if (tg.initDataUnsafe && tg.initDataUnsafe.user) {
      this.userId = tg.initDataUnsafe.user.id;
      this.loadScoreAndLevels();
    }
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
}
h1, h2, h3 {
  color: #007BFF;
  margin: 20px 0;
  font-weight: normal;
}
.page-desc {
  color: #007BFF;
}
.score {
  font-weight: bold;
  font-size: 24px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.card h2 {
  color: #007BFF;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card img {
  max-width: 100%;
  height: auto;
  margin-bottom: 12px;
  display: block;
  margin: 0 auto;
}

.card caption {
  display: block;
  margin: 10px 0;
}

.card span {
  display: block;
  color: #888;
  font-size: 14px;
  margin-top: 10px;
}

.card button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 18px;
}

.card button:disabled {
  background-color: #cccccc;
}

.nav {
  margin-top: 30px;
  text-align: center;
}
.nav a {
  background-color: #007BFF;
  color: #ffffff;
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  display: block;
}
</style>

import { createRouter, createWebHistory } from 'vue-router';
import MainApp from '../components/MainApp.vue';
import Shop from '../components/ShopCard.vue'; // Убедитесь, что путь указан правильно

const routes = [
  {
    path: '/',
    name: 'MainApp',
    component: MainApp
  },
  {
    path: '/shop',
    name: 'ShopCard',
    component: Shop // Убедитесь, что компонент Shop.vue существует и путь указан правильно
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
